<template>
  <layout :showTopBanner="false" :showSidebar="false">
    <app-header :leftLogoPersist="true" :showMobileMenuButton="false" />
    <v-card class="container" rounded="xl">
      <h1 class="top-title">Just one more step!</h1>
      <h1>Create your organization</h1>

      <organization-form />
    </v-card>
  </layout>
</template>
<script>
import Layout from "../app/components/layouts/DefaultLayout.vue";
import Header from "../app/components/headers/DefaultHeader";
import OrganizationForm from "./components/OrgCreateForm.vue";
import isApplicableDomain from "../app/utils/applicable-domain-helper";

export default {
  name: "CreateOrg",
  components: {
    layout: Layout,
    "app-header": Header,
    "organization-form": OrganizationForm,
  },
  metaInfo: {
    title: "Create your organization",
  },
  computed: {
    isSystemAdmin() {
      return this.$store.state.user.isSystemAdmin;
    },
  },
  async mounted() {
    // check if user has belonged to any organization
    try {
      if (this.isSystemAdmin) {
        return;
      }
      const hasOrg = await this.checkUserOrg();
      if (hasOrg) {
        throw new Error("User already belonged to an organization");
      }
      // check if user email is applicable for new organization
      const isApplicableEmailDomain = await this.checkUserEmail();
      if (!isApplicableEmailDomain) {
        throw new Error("User email is not applicable for new organization");
      }
    } catch (e) {
      this.$store.dispatch("callAlert", e.message);
      this.$router.push(`/users/${this.$store.state.user.username}`);
    }
  },
  methods: {
    async checkUserOrg() {
      await this.$store.dispatch("loadUserOrganization");
      const orgs = this.$store.state.user.organizations;
      return Array.isArray(orgs) && orgs.length > 0;
    },
    async checkUserEmail() {
      let userInfo = this.$store.state.user.info;
      // if user info does not exist =>
      if (!userInfo) {
        await this.$store.dispatch("loadUserInfo");
        userInfo = this.$store.state.user.info;
      }
      // email exists in user profile
      if (userInfo.email) {
        const emailDomain = userInfo.email.split("@")[1].trim();
        return isApplicableDomain(emailDomain);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 700px;
  width: 70%;
  flex: 1;
  margin: 0 auto;
  margin-top: 30px;
  padding: 30px 48px;
  .top-title {
    color: $inqliGreen;
    font-size: 18px !important;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
