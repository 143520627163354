<template>
  <form @submit.prevent="submit">
    <h3 class="form-section-title">Tell us about your organization.</h3>
    <div class="form-group">
      <div class="logo-input-container">
        <v-img
          class="logo-preview mx-5"
          :aspect-ratio="1"
          :max-height="130"
          :src="previewLogo"
          contain
          v-if="previewLogo"
        >
        </v-img>
        <div class="logo-preview-placeholder" v-else>
          <p>Logo will show here.</p>
          <p class="logo-preview-note">Only PNG, JPG and JPEG are accepted.</p>
        </div>
        <div>
          <upload-file-button
            v-model="orgLogo"
            @change="setPreviewLogo"
            title="Upload image"
            width="150"
          />
        </div>
      </div>
      <div class="mt-2">
        <v-text-field
          label="Name"
          v-model.trim="orgName"
          class="form-item"
          :error-messages="inputError.name"
        />
        <v-text-field
          label="Website"
          :value="orgWebsite"
          :readonly="!isSystemAdmin"
          class="form-item"
          persistent-hint
        />
        <v-checkbox
          aria-label="website prefix checkbox"
          v-model="hasWebsitePrefix"
          class="form-item website-prefix-checkbox"
          color="inqliPurple"
          dense
        >
          <template v-slot:label>
            <span class="website-prefix-checkbox-label"
              >Add <code>www</code> to website</span
            >
          </template>
        </v-checkbox>
        <v-select
          class="form-item"
          label="Type"
          :items="orgTypeList"
          v-model="orgType"
        />
      </div>
    </div>

    <v-textarea
      class="form-item"
      label="Description"
      v-model="orgDescription"
      dense
      rows="3"
      counter
      :error-messages="inputError.description"
    />
    <h3 class="form-section-title">Location</h3>
    <div class="form-group">
      <location-input
        class="form-group"
        v-model="location"
        :inputError="inputError.country"
      />
    </div>
    <div class="form-buttons">
      <outline-button width="40%" @click="goToDashboard"> Skip </outline-button>
      <inqli-button width="40%" type="submit" :isProcess="isSubmitting"
        >Submit</inqli-button
      >
    </div>
  </form>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import InqliButton from "../../app/components/buttons/Button.vue";
import OutlineButton from "../../app/components/buttons/OutlineButton.vue";
import sendRequest from "../../app/utils/send-request-helper";
import { isValidImageFormat } from "../../app/utils/validation-helper";
import uploadImage from "../../app/utils/upload-image-helper";
import UploadFileButton from "../../app/components/media/UploadFileButton.vue";
import LocationInput from "../../app/components/inputs/LocationInput.vue";

export default {
  name: "OrgForm",
  components: {
    "inqli-button": InqliButton,
    "outline-button": OutlineButton,
    "upload-file-button": UploadFileButton,
    "location-input": LocationInput,
  },
  data() {
    const userEmail = this.$store.state.user.info
      ? this.$store.state.user.info.email
      : "";
    return {
      inputError: {
        name: "",
        description: "",
        logo: "",
        country: "",
      },
      orgDomain: userEmail.split("@")[1],
      orgWebsite: "https://www." + userEmail.split("@")[1],
      hasWebsitePrefix: true,
      orgName: "",
      orgType: "company",
      orgDescription: "",
      orgLogo: null,
      location: {
        city: "",
        state: "",
        country: "",
      },
      stateNameList: [],
      cityNameList: [],
      isSubmitting: false,
      previewLogo: null,
    };
  },
  validations: {
    orgName: { required },
    orgLogo: {
      isValidLogo(file) {
        if (file) {
          return isValidImageFormat(file);
        }
        return true; // logo can be null
      },
    },
    orgDescription: { required },
    location: {
      country: { required },
    },
  },
  computed: {
    isSystemAdmin() {
      return this.$store.state.user.isSystemAdmin;
    },
    orgTypeList() {
      return ["company", "post-secondary", "highschool", "non-profit"];
    },
    orgNameError() {
      const errors = [];
      if (this.$v.orgName.$invalid && !this.$v.orgName.required) {
        errors.push("Organization name is required.");
      }
      return errors;
    },
  },
  watch: {
    hasWebsitePrefix(val) {
      if (val) {
        this.orgWebsite = this.orgWebsite.replace("https://", "https://www.");
      } else {
        this.orgWebsite = this.orgWebsite.replace("www.", "");
      }
    },
    orgName() {
      this.validateOrgName();
    },
    orgDescription() {
      this.validateOrgDescription();
    },
    orgLogo() {
      this.validateOrgLogo();
    },
  },
  beforeDestroy() {
    this.clearData();
  },
  methods: {
    validateOrgName() {
      if (this.$v.orgName.$invalid && !this.$v.orgName.required) {
        this.inputError.name = "Organization name is required.";
      } else {
        this.inputError.name = "";
      }
    },
    validateOrgLogo() {
      if (this.$v.orgLogo.$invalid && !this.$v.orgLogo.isValidLogo) {
        this.inputError.logo = "Invalid logo format";
      } else {
        this.inputError.logo = "";
      }
    },
    validateOrgDescription() {
      if (this.$v.orgDescription.$invalid && !this.$v.orgDescription.required) {
        this.inputError.description = "Organization description is required.";
      } else {
        this.inputError.description = "";
      }
    },
    validateOrgCountry() {
      if (this.location.country === "") {
        this.inputError.country = "Country is required.";
      } else {
        this.inputError.country = "";
      }
    },
    validateImageFormat(file) {
      return isValidImageFormat(file);
    },
    setPreviewLogo(file) {
      try {
        if (file) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.image = new Image();
            this.previewLogo = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.previewLogo = null;
        }
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          error.message || "Can not upload image."
        );
      }
    },
    async submit() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.resetInputError();
      const isInputValid = this.validate();
      if (isInputValid) {
        try {
          // upload logo
          let imageUrl = "";
          if (this.orgLogo) {
            imageUrl = await uploadImage(this.orgLogo);
          }
          const data = {
            name: this.orgName,
            website_url: this.orgWebsite,
            type: this.orgType,
            logo_url: imageUrl,
            description: this.orgDescription,
            location: { ...this.location },
            domain: this.orgDomain,
          };
          const createdOrg = await this.createOrg(data);
          await this.$store.dispatch("loadUserOrganization");
          this.$router.push("/organizations/" + createdOrg.id);
        } catch (err) {
          this.goToDashboard();
        }
      }
      this.isSubmitting = false;
    },
    async createOrg(orgDetails) {
      const createdOrgResponse = await sendRequest({
        url: "/resources/organizations",
        method: "POST",
        body: orgDetails,
        isAuth: true,
      });
      const userId = this.$store.state.user.username;
      await sendRequest({
        url: `/relationships/member?source_id=${userId}&dest_id=${createdOrgResponse.id}`,
        method: "POST",
        isAuth: true,
        body: {
          is_admin: true,
        },
      });
      return createdOrgResponse;
    },
    resetInputError() {
      this.inputError = {
        name: "",
        country: "",
        description: "",
        logo: "",
      };
    },
    validate() {
      if (this.$v.$invalid) {
        this.validateOrgName();
        this.validateOrgLogo();
        this.validateOrgDescription();
        this.validateOrgCountry();
        return false;
      }
      return true;
    },
    goToDashboard() {
      this.$router.push("/users/" + this.$store.state.user.username);
    },
    // get domain from a link (a link may not be a valid url)
    getDomain(link) {
      return new URL(link).hostname;
    },
    // this method is to clear the data when the form component is destroyed.
    clearData() {
      this.org = null;
      this.inputError = null;
      this.previewLogo = null;
      this.stateNameList = null;
      this.cityNameList = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.form-group {
  display: flex;
  justify-content: space-between;
}
.form-item {
  margin: 0 20px;
}
.logo-input-container {
  width: 42%;
}
.form-section-title {
  margin-top: 25px;
}
.logo-preview-placeholder {
  width: 80%;
  height: 135px;
  margin: 0 auto;
  background-color: $inqliIconGray;
  border: 2px dashed $inqliGray;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  vertical-align: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 35px;
  p {
    font-weight: bold;
    color: $inqliDarkBlue;
  }
  .logo-preview-note {
    font-size: 11px;
    font-style: italic;
    margin-top: -10px;
    font-weight: normal;
  }
}
.website-prefix-checkbox {
  margin-top: -20px;
  .website-prefix-checkbox-label {
    font-size: 13px;
  }
}
@media (max-width: $breakpoint-mobile) {
  .form-group {
    display: block;
  }
  .logo-input-container {
    width: 90%;
    margin: auto;
  }
}
</style>
