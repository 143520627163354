var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { showTopBanner: false, showSidebar: false } },
    [
      _c("app-header", {
        attrs: { leftLogoPersist: true, showMobileMenuButton: false }
      }),
      _c(
        "v-card",
        { staticClass: "container", attrs: { rounded: "xl" } },
        [
          _c("h1", { staticClass: "top-title" }, [
            _vm._v("Just one more step!")
          ]),
          _c("h1", [_vm._v("Create your organization")]),
          _c("organization-form")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }