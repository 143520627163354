import { render, staticRenderFns } from "./OrgCreate.vue?vue&type=template&id=996e4e30&scoped=true&"
import script from "./OrgCreate.vue?vue&type=script&lang=js&"
export * from "./OrgCreate.vue?vue&type=script&lang=js&"
import style0 from "./OrgCreate.vue?vue&type=style&index=0&id=996e4e30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "996e4e30",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('996e4e30')) {
      api.createRecord('996e4e30', component.options)
    } else {
      api.reload('996e4e30', component.options)
    }
    module.hot.accept("./OrgCreate.vue?vue&type=template&id=996e4e30&scoped=true&", function () {
      api.rerender('996e4e30', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/organizations/OrgCreate.vue"
export default component.exports